import React, {useCallback, useState} from 'react';
import {
    Box, Checkbox,
    Grid,
    IconButton,
    InputLabel, List, ListItem, ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import {GlobalTransIntl} from "../../../helper/GlobalTrans";
import ModuleElementOptions from "../../../helper/ModuleElementOptions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import {inputOnlyInteger, selectIconComponent} from "../../../helper/Helper";
import TranslationsInput from "../../translations/TranslationsInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {v4 as uuidv4} from "uuid";

const QuestionnaireElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTransIntl(props.title, intl);

    const getDefaultQuestion = {
        label: '',
        dimension: '',
        revert: false,
        id: uuidv4()
    };

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    // optional - when having answers
    const [questions, setQuestions] = useState((props.questions.length) ? props.questions : [getDefaultQuestion]);
    const [options, setOptions] = useState((props.options.length) ? props.options : ['']);
    const [questionsPerPage, setQuestionsPerPage] = useState(props.questionsPerPage);
    const [showAllLabels, setShowAllLabels] = useState(props.showAllLabels);
    const [isCantAnswer, setIsCantAnswer] = useState(props.isCantAnswer || false);

    const updateQuestion = (index, content) => {
        setQuestions(prev => {
            const newQuestions = [...prev];
            const newQuestion = {...prev[index], ...content};

            newQuestions.splice(index, 1);
            newQuestions.splice(index, 0, newQuestion);

            return newQuestions;
        });
    };

    const addQuestion = () => {
        setQuestions(prev => {
            const newQuestions = [...prev];
            const defaultQuestionWithUUID = {
                ...getDefaultQuestion,
                id: uuidv4()
            };
            newQuestions.push(defaultQuestionWithUUID);

            return newQuestions;
        });
    };

    const removeQuestion = (index) => {
        setQuestions(prev => {
            const newQuestions = [...prev];

            newQuestions.splice(index, 1);

            return newQuestions;
        });

        setTranslations(prev => {
            const translations = [...prev];

            translations.forEach((translation) => {
                translation['questions'].splice(index, 1);
            });

            return translations;
        });
    };

    const getDimensionNameById = useCallback((id) => {
        const dimension = props.dimensions.find(dimension => dimension.id === id);

        if (dimension) {
            return ` (${dimension.name})`;
        }
    }, [props.dimensions]);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            options,
            questions,
            questionsPerPage,
            showAllLabels,
            translations,
            isCantAnswer,
        };

        props.updateContent(props, data);
    }, [props, options, questions, questionsPerPage, showAllLabels, isCantAnswer, translations]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <TextField
                    label={GlobalTransIntl('questions_per_page', intl)}
                    autoComplete={'off'}
                    variant="outlined"
                    onChange={(e) => {
                        inputOnlyInteger(e, setQuestionsPerPage)
                    }}
                    value={questionsPerPage}
                    required
                />
            </Box>
            <Box mb={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCantAnswer}
                            onChange={(e) => setIsCantAnswer(e.target.checked)}
                            inputProps={{'aria-label': GlobalTransIntl('cant_answer', intl)}}
                            title={GlobalTransIntl('cant_answer', intl)}
                        />
                    }
                    label={GlobalTransIntl('cant_answer', intl)}
                />
            </Box>
            <Box mb={2}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAllLabels}
                            onChange={(e) => setShowAllLabels(e.target.checked)}
                            inputProps={{'aria-label': GlobalTransIntl('show_all_labels', intl)}}
                            title={GlobalTransIntl('show_all_labels', intl)}
                        />
                    }
                    label={GlobalTransIntl('show_all_labels', intl)}
                />
            </Box>
            <Box mb={2}>
                <Typography>{GlobalTransIntl('options', intl)}</Typography>
            </Box>
            <ModuleElementOptions
                options={options}
                setOptions={setOptions}
                label={'option'}
                setTranslationLanguage={setTranslationLanguage}
                translations={translations}
                translationLanguage={translationLanguage}
                translationsField={'options'}
                setTranslations={setTranslations}
            />

            <Box mb={2}>
                <Typography>{GlobalTransIntl('questions', intl)}</Typography>
            </Box>
            {
                questions.map((question, index) =>
                    <Box mb={2} key={index}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <TranslationsInput
                                    field={'questions'}
                                    fieldType={'json'}
                                    fieldIndex={question.id}
                                    defaultValue={question.label}
                                    setDefaultValue={(value) => {
                                        updateQuestion(index, {label: value})
                                    }}
                                    translations={translations}
                                    language={translationLanguage}
                                    label={GlobalTransIntl('question', intl) + ' ' + (index + 1)}
                                    setTranslations={setTranslations}
                                    attributes={{
                                        required: true,
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <FormControl variant={'outlined'} required>
                                    <InputLabel id={"module-dimensions-select-label-" + index}>
                                        {GlobalTransIntl('dimensions', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId={"module-dimensions-select-label-" + index}
                                        value={question.dimension}
                                        label={GlobalTransIntl('dimensions', intl)}
                                        onChange={(event) => updateQuestion(index, {dimension: event.target.value})}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            props.dimensions.map((dimension, index) => (
                                                <MenuItem key={index} value={dimension.id}>
                                                    {dimension.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} className={'align-self--center'}>
                                <Checkbox
                                    checked={question.revert}
                                    onChange={(e) => updateQuestion(index, {revert: e.target.checked})}
                                    inputProps={{'aria-label': GlobalTransIntl('revert_score', intl)}}
                                    title={GlobalTransIntl('revert_score', intl)}
                                />
                            </Grid>
                            {
                                questions.length > 1 &&
                                <Grid item xs={1} className={'align-self--center text-align-center'}>
                                    <IconButton
                                        onClick={() => removeQuestion(index)}
                                        title={GlobalTransIntl('delete', intl)}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                )
            }
            <Box mb={2}>
                <IconButton onClick={addQuestion}>
                    <FontAwesomeIcon icon={faPlus} size={"xs"}/>
                </IconButton>
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translationLanguage={translationLanguage}
                setTranslationLanguage={setTranslationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.options.length > 0 &&
                <Box mb={2}>
                    <Typography>{GlobalTransIntl('options', intl)}</Typography>
                    <List>
                        {
                            props.options.map((option, index) =>
                                <ListItem key={index}>
                                    <ListItemText primary={`${(index + 1)} - ${option}`}/>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
            }
            {
                props.questions.length > 0 &&
                <Box mb={2}>
                    <Typography>{GlobalTransIntl('questions', intl)}</Typography>
                    <List>
                        {
                            props.questions.map((question) =>
                                <ListItem key={question.id}>
                                    <ListItemText primary={question.label + getDimensionNameById(question.dimension)}/>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
            }
        </Box>
    );
};

QuestionnaireElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    dimensions: PropTypes.array.isRequired,
}

// Default config for the index.js array
export const QuestionnaireElementConfig = {
    title: 'questionnaire',
    options: [],
    questions: [],
    translations: [],
    validationType: 'array',
    questionsPerPage: 20,
    showAllLabels: false,
    isCantAnswer: false,
}

export default QuestionnaireElement;