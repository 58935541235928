import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";

const TranslationsInput = (props) => {
    const findTranslation = useCallback((translations) => {
        return translations.find((translation => translation.language === props.language || translation?.language['@id'] === props.language));
    }, [props.language]);

    const getValue = useCallback((field) => {
        if (props.language) {
            const translation = findTranslation(props.translations);

            if (translation && translation[field]) {
                if (props.fieldType === 'array' || props.fieldType === 'json') {
                    return translation?.[field][props.fieldIndex] || '';
                } else {
                    return translation[field];
                }

            } else {
                return '';
            }
        } else {
            return props.defaultValue;
        }
    }, [props.language, props.translations, props.defaultValue, findTranslation, props.fieldIndex, props.fieldType]);

    const setValue = useCallback((value) => {
        if (props.language) {
            const newTranslations = [...props.translations];
            const translation = findTranslation(newTranslations);

            if (translation) {
                if (props.fieldType === 'array') {
                    if (translation[props.field]) {
                        translation[props.field][props.fieldIndex] = value;
                    } else {
                        const array = [];
                        array[props.fieldIndex] = value;
                        translation[props.field] = array;
                    }
                } else if (props.fieldType === 'json') {
                    if (translation[props.field]) {
                        translation[props.field][props.fieldIndex] = value;
                    } else {
                        translation[props.field] = {
                            [props.fieldIndex]: value
                        };
                    }
                } else {
                    translation[props.field] = value;
                }
            } else {
                switch (props.fieldType) {
                    case 'array':
                        const array = [];
                        array[props.fieldIndex] = value;

                        newTranslations.push({
                            language: props.language,
                            [props.field]: array,
                        });
                        break;
                    case 'json':
                        newTranslations.push({
                            language: props.language,
                            [props.field]: {
                                [props.fieldIndex]: value
                            },
                        });
                        break;
                    default:
                        newTranslations.push({
                            language: props.language,
                            [props.field]: value,
                        });
                        break;
                }
            }

            props.setTranslations(newTranslations);
        } else {
            props.setDefaultValue(value);
        }
    }, [props, findTranslation]);

    return (
        <TextField label={props.label}
                   autoComplete={'off'}
                   variant="outlined"
                   onChange={(e) => {
                       setValue(e.target.value)
                   }}
                   value={getValue(props.field)}
                   {...props.attributes}
        />
    );
};

TranslationsInput.propTypes = {
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    setDefaultValue: PropTypes.func.isRequired,
    translations: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
    fieldType: PropTypes.string,
    fieldIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    setTranslations: PropTypes.func.isRequired,
    attributes: PropTypes.object,
}

TranslationsInput.defaultProps = {
    attributes: {},
    fieldType: '',
}

export default TranslationsInput;