import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import {useIntl} from "react-intl";
import PropTypes from 'prop-types';

import {useStore} from "../../store/useStore";
import config from "../../config/config";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";

// Fontawesome
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClone, faSearch, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

// Material UI
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Box, Button, Grid, Modal} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

// Components
import Spinner from "../spinner/Spinner";
import Notifications from "../notifications/Notifications";
import {helperCatchErrors, selectIconComponent} from "../../helper/Helper";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";
import CustomDialog from "../../helper/CustomDialog";

const LoadingDataGrid = (props) => {
    const {state} = useStore();
    const intl = useIntl();
    const apiUrl = props.apiUrl;

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [rows, setRows] = useState([]);
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [filter, setFilter] = React.useState({});
    const [selectFilterArrays, setSelectFilterArrays] = React.useState({});
    const [filterField, setFilterField] = React.useState('');
    const [filterValue, setFilterValue] = React.useState('');
    const [filterOperatorValue, setFilterOperatorValue] = React.useState('');
    const [openAxiosRequestsFilter, setOpenAxiosRequestsFilter] = React.useState(props.toolbarItems.length);
    const [openAxiosRequestsDataGrid, setOpenAxiosRequestsDataGrid] = React.useState(true);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);
    const [columns, setColumns] = React.useState([]);
    const [deleteItem, setDeleteItem] = React.useState('');
    const [pageSize, setPageSize] = React.useState(15);

    // Duplicate action
    const [duplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [duplicateObject, setDuplicateObject] = useState(null);
    const [duplicateName, setDuplicateName] = useState('');

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationDeleteSuccess, setNotificationDeleteSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);

    const catchError = error => {
        if (axios.isCancel(error)) {

        } else if (error.response) {
            console.log(error.response);
        }
    }

    const showError = useCallback((errorMessage = '') => {
        setErrorMessage(errorMessage);

        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getDataGridContent = useCallback(() => {
        let url = config.apiUrl + `/${props.apiUrl}`;

        if (props.dataGridFilter && props.dataGridFilter.length) {
             url += ApiFilterBuilder(props.dataGridFilter);
        }

        axios.get(url, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setRows(res.data['hydra:member']);
                }
                setOpenAxiosRequestsDataGrid(false);
            })
            .catch(catchError);
    }, [props.apiUrl, state.token, cancelToken.token, props.dataGridFilter]);

    const openDuplicateModal = () => {
        setDuplicateModalOpen(true);
    }

    const closeDuplicateModal = () => {
        setDuplicateModalOpen(false);
        setDuplicateName('');
        setDuplicateObject(null);
    }

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }
        };
    }, [timeoutWatcher]);

    useEffect(() => {
        if (!mounted) {
            if (props.toolbarItems.length) {
                let internOpenAxiosRequestsFilter = props.toolbarItems.length,
                    internSelectFilterArrays = {},
                    internFilter = {};

                props.toolbarItems.forEach((item) => {
                    internFilter = {
                        ...internFilter,
                        [item.name]: ''
                    };
                    setFilter(internFilter);

                    if (item.type === 'select') {
                        axios.get(config.apiUrl + `/${item.getUrl}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                            .then(res => {
                                if (res.data) {
                                    internSelectFilterArrays = {
                                        ...internSelectFilterArrays,
                                        [item.name]: res.data['hydra:member']
                                    };
                                    setSelectFilterArrays(internSelectFilterArrays);
                                }
                                internOpenAxiosRequestsFilter--;
                                setOpenAxiosRequestsFilter(internOpenAxiosRequestsFilter);
                            })
                            .catch(catchError);
                    } else {
                        internOpenAxiosRequestsFilter--;
                        setOpenAxiosRequestsFilter(internOpenAxiosRequestsFilter);
                    }
                });
            }

            getDataGridContent();
            setMounted(true);
        }
    }, [mounted, getDataGridContent, props.toolbarItems, state.token, cancelToken]);

    const dataGridDeleteItem = useCallback(() => {
        if (!deleteItem) {
            return;
        }

        resetNotifications();
        clearTimeout(timeoutWatcher);

        axios.delete(config.apiUrl + `/${props.apiUrl}/${deleteItem}`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.status === 204) {
                    setNotificationDeleteSuccess(true);

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));

                    getDataGridContent();
                } else {
                    console.log(res);
                    showError("Status " + res.status);
                }
            })
            .catch(error => {
                if (error.response) {
                    if(error.response && error.response.data && error.response.data.hasOwnProperty('hydra:description') && error.response.data['hydra:description'].length) {
                        setErrorMessage(error.response.data['hydra:description']);
                    }

                    showError();
                }
            });
    }, [deleteItem, cancelToken.token, getDataGridContent, props.apiUrl, showError, state.token, timeoutWatcher]);

    useEffect(() => {
        if (props.deleteAction || props.columnActions) {
            const dataGridDuplicateItem = (object) => {
                setDuplicateObject(object);
                openDuplicateModal();
            }

            const getColumnActions = (params) => {
                if (props.columnActions) {
                    return props.columnActions(params);
                }
            }

            const columnsAction = {
                field: "",
                headerName: GlobalTransIntl('field_actions', intl),
                disableClickEventBubbling: true,
                width: props.columnActionsWidth,
                renderCell: (params) => {
                    return (
                        <React.Fragment>
                            {getColumnActions(params)}
                            {
                                props.duplicateAction &&
                                <IconButton
                                    onClick={()=> dataGridDuplicateItem(params.row)}
                                    aria-label={GlobalTrans('duplicate')}
                                    title={GlobalTrans('duplicate')}
                                >
                                    <FontAwesomeIcon icon={faClone} size={"xs"}/>
                                </IconButton>
                            }
                            {
                                props.deleteAction &&
                                <IconButton
                                    onClick={() => {
                                        setDeleteItem(params.id);
                                    }}
                                    aria-label={GlobalTransIntl('title_delete', intl)}
                                    title={GlobalTransIntl('title_delete', intl)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                                </IconButton>
                            }
                        </React.Fragment>
                    );
                }
            }

            props.columns.push(columnsAction);
        }

        setColumns(props.columns);
    }, [props, cancelToken.token, state.token, getDataGridContent, intl, timeoutWatcher, showError]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken])

    useEffect(() => {
        if (props.reload[0] === true) {
            props.reload[0] = false;
            setOpenAxiosRequestsDataGrid(true);
            getDataGridContent();
        }
    }, [props.reload, getDataGridContent])

    const resetFilter = () => {
        let internFilter = filter;

        Object.keys(internFilter).forEach((item) => {
            internFilter = {
                ...internFilter,
                [item]: ''
            };
        });

        setFilter(internFilter);
        setFilterField('');
        setFilterOperatorValue('');
        setFilterValue('');
    }

    const getResetFilterValues = () => {
        let internFilter = filter;

        Object.keys(internFilter).forEach((item) => {
            internFilter = {
                ...internFilter,
                [item]: ''
            };
        });

        return internFilter;
    }

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationDeleteSuccess(false);
        setNotificationError(false);
    };

    const handleEditCellChangeCommitted = ({ id, field, props }) => {
        let oldRows = rows,
            oldRow = oldRows.find((element) => {
                return element.id === id;
            }),
            oldValue = oldRow[field];

        if (oldValue === props.value) {
            return;
        }

        // update local rows
        oldRow[field] = props.value;

        const data = {
            [field]: props.value
        };

        axios.patch(config.apiUrl + `/${apiUrl}/${id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/merge-patch+json'}}))
            .then(res => {
                resetNotifications();
                if (res.status === 200) {
                    setNotificationSuccess(true);

                    setTimeoutWatcher(setTimeout(() => {
                        resetNotifications();
                    }, 3000));
                } else {
                    showError();
                    console.log(res);
                }
            })
            .catch(error => {
                resetNotifications();
                showError();
                console.log(error)
            });
    }

    const getToolbarColConfig = () => {
        if (props.toolbarItems.length > 2) {
            return {
                xs: 12,
                lg: 3
            }
        }

        return {
            xs: 12,
            lg: 4
        }
    }

    const CustomNoRowsOverlay = () => {
        return (
            <GridOverlay>
                <div>{GlobalTransIntl('datagrid_not_found', intl)}</div>
            </GridOverlay>
        )
    }

    const getDuplicateModal = () => {
        if (!props.duplicateAction) {
            return null;
        }

        const onDuplicateSubmit = (event) => {
            event.preventDefault();

            if (props.duplicateSubscriberFunction) {
                props.duplicateSubscriberFunction(duplicateObject);
            }

            // Prepare object
            duplicateObject.id = null;
            duplicateObject.name = duplicateName;
            duplicateObject.duplicate = true;

            axios.post(config.apiUrl + `/${apiUrl}`, duplicateObject, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data) {
                        setNotificationSuccess(true);

                        setTimeoutWatcher(setTimeout(() => {
                            resetNotifications();
                        }, 3000));

                        getDataGridContent();
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);

            closeDuplicateModal();
        }

        return (
            <Modal
                open={duplicateModalOpen}
                onClose={closeDuplicateModal}
                className={'MuiModal-root'}
            >
                <div className={'main-modal modal-size-md'}>
                    <form onSubmit={onDuplicateSubmit}>
                        <Box mb={2}>
                            <TextField label={GlobalTransIntl('name', intl)}
                                       autoComplete={'off'}
                                       variant="outlined"
                                       onChange={(e) => {
                                           setDuplicateName(e.target.value)
                                       }}
                                       value={duplicateName}
                                       required
                            />
                        </Box>

                        <Button variant="contained" color="primary" className={'fa-pull-right'} type={"submit"}>
                            {
                                GlobalTransIntl('duplicate', intl)
                            }
                        </Button>
                    </form>
                </div>
            </Modal>
        );
    };

    return (
        (
            (openAxiosRequestsFilter > 0 || openAxiosRequestsDataGrid) &&
            <Spinner show={true} rowClass={'p-5'}/>
        )
        ||
        (
            <React.Fragment>
                {
                    props.toolbarItems.length !== 0 &&
                    <div className={'toolbar'}>
                        <Grid container spacing={2}>
                            {
                                props.toolbarItems.map((item, index) => {
                                    if (item.type === 'select') {
                                        return (
                                            <Grid item key={index} {...getToolbarColConfig()}>
                                                <FormControl variant={'outlined'}
                                                             id={`toolbar--filter-${item.name}`}>
                                                    <InputLabel>
                                                        {item.label}
                                                    </InputLabel>
                                                    <Select
                                                        id={`toolbar--filter-${item.name}-select`}
                                                        value={filter[item.name]}
                                                        onChange={(event) => {
                                                            setFilter({
                                                                ...getResetFilterValues(),
                                                                [item.name]: event.target.value
                                                            });
                                                            setFilterField(item.name);
                                                            setFilterOperatorValue((item.filterOperator) ? item.filterOperator : 'contains');
                                                            setFilterValue(event.target.value);
                                                        }}
                                                        label={item.label}
                                                        IconComponent={selectIconComponent}
                                                    >
                                                        {
                                                            selectFilterArrays[item.name].map((item, key) =>
                                                                <MenuItem value={item.name} key={key}
                                                                          data-name={item.name}>{item.name}</MenuItem>
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )
                                    } else if (item.type === 'text') {
                                        return (
                                            <Grid item key={index} {...getToolbarColConfig()}>
                                                <TextField
                                                    id={`toolbar--filter-${item.name}`}
                                                    label={item.label}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <FontAwesomeIcon icon={faSearch}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={(event) => {
                                                        setFilter({
                                                            ...getResetFilterValues(),
                                                            [item.name]: event.target.value
                                                        });
                                                        setFilterField(item.name);
                                                        setFilterOperatorValue((item.filterOperator) ? item.filterOperator : 'contains');
                                                        setFilterValue(event.target.value);
                                                    }}
                                                    variant="outlined"
                                                    value={filter[item.name]}
                                                />
                                            </Grid>
                                        )
                                    }

                                    return null;
                                })
                            }
                            <Grid item className={'align-self--center'} {...getToolbarColConfig()}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<FontAwesomeIcon icon={faTrashAlt}/>}
                                    onClick={resetFilter}
                                >
                                    {GlobalTransIntl('button_reset_filter', intl)}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                }
                <div className={`datagrid--wrapper`}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 15, 20, 25, 50]}
                        disableColumnFilter
                        disableSelectionOnClick
                        filterModel={{
                            items: [
                                {
                                    columnField: filterField,
                                    operatorValue: filterOperatorValue,
                                    value: filterValue
                                }
                            ],
                        }}
                        onSelectionModelChange={(newSelection) => {
                            setSelectionModel(newSelection.selectionModel);
                        }}
                        selectionModel={selectionModel}
                        onEditCellChangeCommitted={handleEditCellChangeCommitted}
                        components={{
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
                <Notifications
                    cols={{
                        xs: 12,
                        lg: {
                            span: 6,
                            offset: 3
                        }
                    }}
                    success={notificationSuccess}
                    delete={notificationDeleteSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
                {getDuplicateModal()}
                <CustomDialog
                    text={GlobalTransIntl('dialog_delete', intl)}
                    agreeFunction={dataGridDeleteItem}
                    open={!!deleteItem}
                    closeFunction={() => setDeleteItem('')}
                />
            </React.Fragment>
        )
    );
};

export default LoadingDataGrid;

LoadingDataGrid.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    toolbarItems: PropTypes.array,
    columnActions: PropTypes.func,
    columnActionsWidth: PropTypes.number,
    duplicateAction: PropTypes.bool,
    duplicateSubscriberFunction: PropTypes.func,
    deleteAction: PropTypes.bool,
    dataGridFilter: PropTypes.array,
    reload: PropTypes.object,
};

LoadingDataGrid.defaultProps = {
    toolbarItems: [],
    columnActionsWidth: 160,
    duplicateAction: false,
    deleteAction: true,
    reload: {0:false},
};